<template>
  <section id="NosPreocupamos" class="weCare" data-aos="fade-up">
    <div class="__content">
      <h2><b>{{ data.title }}</b></h2>
      <p>{{ data.description }}</p>
      <div class="__card_container">
        <div class="card shadow ___bases_card" v-for="(card) in data.cards" :key="card.id">
          <img
            :src="require(`../../assets/${card.img}`)"
            :alt="card.name"
            loading="lazy"
          />
          <h3>
            <b>{{ card.title }}</b>
          </h3>
          <p> {{ card.text }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import store from '../../store';

export default {
  name: "NosPreocupamos",
  data() {
    return {
      data: {
        title: 'Nos preocupamos por ti',
        description: 'Desde nuestras bases',
        cards: [
          {
            id: 1,
            img: 'img/proposito.webp',
            title: 'Propósito',
            text: 'Juntar a las nuevas generaciones con profesionales y empresarios con experiencia para construir un mundo mejor.'
          },
          {
            id: 2,
            img: 'img/vision.webp',
            title: 'Visión',
            text: 'Ser una plataforma global que dé acceso a los mejores talentos, prácticas, redes y conocimientos para construir empresas.'
          },
          {
            id: 3,
            img: 'img/mision.webp',
            title: 'Misión',
            text: 'Ofrecer la mejor hoja de ruta y programas para ayudar a que emprendedores y empresarios cumplan sus sueños.'
          },
        ]
      }
    }
  },
  mounted() {
    this.fetchData();
  },
  watch: {
    "$store.state.sections": function() {
      this.fetchData();
    }
  },
  methods: {
    fetchData() {
       if (store.state.sections.careAboutYou) {
      this.data = store.state.sections.careAboutYou.components;}
    }
  }
}
</script>

<style scoped lang="scss">
.weCare {
  display: inline-flex;
  justify-content: center;
  text-align: center;
  min-height: 50vh;
  width: 100%;

  .__content {
    max-width: 1366px;
    padding: 20px;
  }

  .__card_container {
    display: grid;
    grid-gap: 25px 0;
    grid-template-columns: 1fr;
    width: 100%;

    .___bases_card {
      border-radius: 6px;
      justify-content: center;
      position: relative;
      padding: 44px;
      text-overflow: ellipsis;
      min-height: 275px;
      max-height: 355px;
      max-width: 100%;
      margin: 70px 0 0;
    }

    .___bases_card img {
      aspect-ratio: 1;
      width: 139px;
      position: absolute;
      place-self: center;
      top: -70px;
    }

    .___bases_card p {
      overflow: hidden;
    }
  }
}

@media (min-width: 992px) {
  .weCare {
    .__card_container {
      grid-gap: 0 3%;
      grid-template-columns: repeat(3, 1fr);
      margin: 54px 0 0;

      .___bases_card {
        min-height: 355px;
        max-height: 455px;
      }
    }
  }
}
</style>
